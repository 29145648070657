.login {
  width: 100vw;
  height: 100vh;
  background-image: url("/background.png");
  background-size: 100%;
  background-position: 50% 100%;
  background-repeat: no-repeat;
  overflow: hidden;
  font-family: "Montserrat", sans-serif;
}
.login-button {
  width: 230px;
  height: 40px;
  border-radius: 20px;
  border: solid 1px #0d0d0d;
  margin: auto;
  line-height: 40px;
  color: #0d0d0d;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
  cursor: pointer;
}
.login-button:hover {
  background: black;
  text-decoration: none;
  color: white;
}
.error {
  text-align: center;
  color: red;
  margin-top: 18px;
  font-size: 1.2rem;
}
.info {
  text-align: center;
  margin-top: 18px;
  font-size: 1.2rem;
}
.icon-google {
  margin-right: 12px;
}
.center-google {
  position: absolute;
  top: 50%;
  width: 100%;
  z-index: 999;
}
.logo-login {
  position: absolute;
  width: 100%;
  top: 50%;
  margin-top: -30vh;
  text-align: center;
}
.icon-bluetab {
  position: absolute;
  bottom: -32px;
  right: -32px;
}

header {
  display: none;
}
