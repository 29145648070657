code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.private-app {
  display: flex;
}
.private-app .toolbar {
  padding-right: 24;
}
